import WebFont from 'webfontloader';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {useEffect, useState} from 'react';
import Home from "./Pages/Home";
import { Analytics } from "@vercel/analytics/react"
import Authenticate from "./Pages/Authenticate";
import Dash from "./Pages/Dash";

import {UserProvider} from "./Contexts/UserContext";
import {Body, LargeTitle} from "./Components/Text";
import Support from "./Pages/Support";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Bebas Neue",serif;
  background: black;
`;


function App() {

    const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Bebas Neue']
      }
    });
    if(window.innerWidth <= 800 && window.location.pathname !== "/support")
        setShowMobileWarning(true)
   }, []);

  return (
  <>
    {showMobileWarning ? (
      <MobileError/>
    ) : (
      <UserProvider>
        <BrowserRouter>
          {/*<SpeedInsights />*/}
          <Analytics />
          {/*<NavBar />*/}
          <AppContainer>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Authenticate />} />
              <Route path="/home" element={<Dash />} />
                <Route path="/support" element={<Support/>} />
            </Routes>
          </AppContainer>
        </BrowserRouter>
      </UserProvider>
    )}
  </>
);
}

const MobileErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
    text-align: center;
  align-items: center;
  height: 100vh;
    padding: 20px;
  color: white;
  background: black; /* Background color for visibility */
  font-family: 'Bebas Neue', serif;
`;

const MobileError = () => {
  return (
      <MobileErrorWrapper>
          <LargeTitle>Ooops</LargeTitle>
          <Body>Overload Insights is better experienced on Desktops/Laptops</Body>
          <Body style={{marginTop: "20px"}}>Why would you want to use the web app when you can use the real deal?</Body>
          <img src="/apple_app_store.svg" alt="My SVG Icon" width={150} height={150}/>
          <img src="/google_play_store.png" alt="My SVG Icon" width={150} height={50}/>
      </MobileErrorWrapper>
  );
};


export default App;
