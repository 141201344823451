import {LargeTitle, SubTitle, Body, Title, Small} from "../../Components/Text";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TextField from "../../Components/TextField";
import {verifyPasswordResetCode, confirmPasswordReset, sendPasswordResetEmail, applyActionCode} from "firebase/auth"
import {auth} from "../../Firebase"
import {ScaleLoader} from "react-spinners";
import {GreenButton} from "../../Components/Buttons";

const SupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ResetPasswordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ResetSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const HeroContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-content: center;
  background: black;

  @media (max-width: 768px) {
    display: none;  /* Hide the hero section on mobile devices */
  }
`;

const HeroImage = styled.img`
  width: auto;
  height: 700px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;


const ResetPasswordView = ({ actionCode }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [error, setError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            setEmail(email);
            setIsLoading(false);
        }).catch((e) => {
            setError(true);
        })
    })

    async function handlePasswordChange() {
        if(!isValidPassword()){
            setPasswordError(true);
        }else {
            confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
                setSuccess(true);
            }).catch((err) => {
                setError(true);
                sendPasswordResetEmail(auth, email);
            })
        }
    }

    function isValidPassword() {
        if (newPassword.length < 8) {
        return false;
        }

        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasLowerCase = /[a-z]/.test(newPassword);

        return hasUpperCase && hasLowerCase;
    }



  return (
    <ResetPasswordWrapper>
      <ResetSection>
        {isLoading ? (
          <LoadingContainer>
            <ScaleLoader color="#B8FE01" height={70} width={10} />
          </LoadingContainer>
        ) : error ? (
          <>
            <Title>Ooops</Title>
            <Body>There was an error processing your request</Body>
            <Body>We've sent you a new email, please follow the link in there</Body>
          </>
        ) : success ? (
          <>
            <Title>Password Reset Successful</Title>
            <Body>Your password has been updated successfully. Maybe right this one down?</Body>
          </>
        ) : (
          <>
            <SubTitle>Reset Password</SubTitle>
            <Small style={{ marginBottom: "40px" }}>
              Enter your new password below
            </Small>
            <TextField
              placeholder="Email"
              value={email}
              setValue={setEmail}
              isValid={true}
              disabled={true}
              type="email"
            />
            <TextField
              placeholder="Password"
              value={newPassword}
              setValue={setNewPassword}
              isValid={isValidPassword()}
              type="password"
            />
            {passwordError && (
              <Small style={{ marginTop: "20px", color: "red" }}>
                Your password needs to be longer than 8 characters with <br /> numbers, upper and lowercase letters.
              </Small>
            )}
            <GreenButton onClick={handlePasswordChange}>Update password</GreenButton>
          </>
        )}
      </ResetSection>
      <HeroContainer>
        <HeroImage src={'/hero_1_final.png'} alt="Hero Image" />
      </HeroContainer>
    </ResetPasswordWrapper>
  );
};

const RecoverEmailView = ({ actionCode }) => {
  const [recoveryStatus, setRecoveryStatus] = useState("");

  useEffect(() => {
    if (actionCode) {
      setRecoveryStatus("Verifying recovery action code...");

      setTimeout(() => {
        setRecoveryStatus("Email recovery code verified. Proceed to recover your email.");
      }, 2000);
    }
  }, [actionCode]);

  return (
    <>
      <LargeTitle>Recover Email</LargeTitle>
      <Body>{recoveryStatus}</Body>
    </>
  );
};

const VerifyEmailView = ({ actionCode }) => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        applyActionCode(auth, actionCode).then((resp) => {
            setSuccess(true);
        }).catch((err)=> {
            setError(true);
        });
    });

    useEffect(() => {
        if (success || error) {
            const redirectURL = "overload://";

            const timer = setTimeout(() => {
                window.location.href = redirectURL;
            }, 2000); // Wait for 2 seconds before redirecting

            return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, [success, error]);

  return (
    <ResetPasswordWrapper>
      <ResetSection>
        {error ? (
          <>
            <Title>Ooops</Title>
            <Body>There was an error processing your request</Body>
            <Body>We've sent you a new email, please follow the link in there</Body>
          </>
        ) : success ? (
          <>
            <Title>Email verified successfully</Title>
            <Body>Your email has been verified. You will be redirected shortly.</Body>
          </>
        ) : (
          <>
            <LoadingContainer>
              <SubTitle>Verifying your email</SubTitle>
            <ScaleLoader color="#B8FE01" height={70} width={10} />
          </LoadingContainer>
          </>
        )}
      </ResetSection>
      <HeroContainer>
        <HeroImage src={'/hero_1_final.png'} alt="Hero Image" />
      </HeroContainer>
    </ResetPasswordWrapper>
  );
};

const DefaultSupportView = () => (
  <>
    <LargeTitle>Support</LargeTitle>
    <Body style={{ textAlign: "center" }}>
      For assistance, please contact our support team.
    </Body>
  </>
);

function Support() {
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState("");
  const [actionCode, setActionCode] = useState("");

  useEffect(() => {
    const modeParam = searchParams.get("mode") || "";
    const actionCodeParam = searchParams.get("oobCode") || "";

    setMode(modeParam);
    setActionCode(actionCodeParam);
  }, [searchParams]);

  const renderContent = () => {
    if (!mode || !actionCode) {
      return <DefaultSupportView />;
    }

    switch (mode) {
      case "resetPassword":
        return <ResetPasswordView actionCode={actionCode} />;
      case "recoverEmail":
        return <RecoverEmailView actionCode={actionCode} />;
      case "verifyEmail":
        return <VerifyEmailView actionCode={actionCode} />;
      default:
        return <DefaultSupportView />;
    }
  };

  return <SupportWrapper>{renderContent()}</SupportWrapper>;
}

export default Support;
