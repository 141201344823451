import React, {useEffect, useState} from 'react';
import {Small, StyledLink, LargeTitle} from '../../Components/Text';
// import { getAuth, OAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Contexts/UserContext';
import styled from 'styled-components';
import { FaApple, FaGoogle  } from 'react-icons/fa';
import CustomDivider from "../../Components/Other";
import TextField from "../../Components/TextField";
import {GreenButton} from "../../Components/Buttons";

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1B1B1D;
  color: white;
  padding: 10px 40px;
    width: 300px;
    margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #333;
  }

  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;

const AppleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1B1B1D;
    width: 300px;
    color: white;
    border: none;
    padding: 10px 40px;
    margin: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #333;
    }

    svg {
        margin-right: 10px;
        font-size: 20px;
    }
`;


const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-content: center;
  background: black;

  @media (max-width: 768px) {
    display: none;  /* Hide the hero section on mobile devices */
  }
`;

const HeroImage = styled.img`
  width: auto;
  height: 700px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const EmailForm = styled.form`
    display: flex;
    flex-direction: column;
`


function Authenticate() {
  const navigate = useNavigate();
  const {user, isLoading} = useUser()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (!isLoading && user){
            navigate("/home");
        }
    });

  const handleSignInWithApple = async () => {
    // const auth = getAuth();
    // const provider = new OAuthProvider('apple.com');
    alert("Disabled for time being as we develop!")
    // signInWithPopup(auth, provider)
    //   .then(() => {
    //     navigate('/home');
    //   })
    //   .catch((error) => {
    //     console.error(error.code, error.message);
    //     console.log(OAuthProvider.credentialFromError(error));
    //   });
  };
  const handleSignInWithGoogle = () => {
      alert("Google Provider coming soon.");
  }
    const handleSignInWithEmail = (e) =>{
      e.preventDefault();
          alert("Not implemented yet")
    }

  const isValidEmail = () => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
  }

  return (
      <LoginWrapper>
          <LoginSection>
              <LargeTitle style={{marginBottom: "10px"}}>Log in</LargeTitle>
            <AppleButton onClick={handleSignInWithApple}>
                <FaApple />
                Sign in with Apple
            </AppleButton>
              <GoogleButton onClick={handleSignInWithGoogle}>
                  <FaGoogle/>
                  Sign in with Google
              </GoogleButton>
              <CustomDivider
                text="Or with email"
                color="white"
                lineColor="#CCCCCC"
                lineHeight="1px"
                textPadding="0 15px"
                fontSize="22px"
                margin="30px 0"
              />
              <EmailForm>
                  <TextField
                    placeholder="Email"
                    value={email}
                    setValue={setEmail}
                    isValid={isValidEmail(email)}
                  />
                  <TextField
                    placeholder="Password"
                    value={password}
                    setValue={setPassword}
                    isValid={true}
                    type="password"
                  />
                  <Small style={{marginTop: "10px"}}>Forgot your password? <StyledLink href="/recover">Reset it.</StyledLink></Small>
                  <GreenButton onClick={handleSignInWithEmail}>Login</GreenButton>
                 <Small style={{marginTop: "10px"}}>
                      By logging in you agree with our{' '}
                      <StyledLink href="https://overloadworkout.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                        T&Cs
                      </StyledLink>{' '}
                      and{' '}
                      <StyledLink href="https://overloadworkout.com/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </StyledLink>.
                 </Small>
              </EmailForm>
          </LoginSection>
            <HeroContainer>
                 <HeroImage src={'/hero_1_final.png'} alt="Hero Image" />
            </HeroContainer>
      </LoginWrapper>
  );
}

export default Authenticate;